<template>
  <div class="home">
    <img class="home__img" :src="require('@/assets/SEV_SMSHR_03.jpg')" alt="" />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {}
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
}
.home__img {
  max-width: 60%;
  margin: 0 auto;
  display: block;
}
</style>
