<template>
  <div id="app" class="container">
    <NavBar/>
    <router-view/>
  </div>
</template>

<script type="text/javascript">
import NavBar from "@/components/NavBar"
export default {
  components: {
    NavBar
  },
}
</script>
<style lang="scss">
html {
  font-size: 100%;
}
#app {
  font-family: "RobotoSlab Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 24px;
}
.container {
  padding: 40px 50px;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 15px;
  }
}
#nav {
  a {

    &.router-link-exact-active {
      text-decoration: underline;
      display: block;
    }
}
}
// @font-face {
//   font-family: "Open Sans Regular";
//   src: local("Open Sans Regular"),
//    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
// }
@font-face {
  font-family: "RobotoSlab Regular";
  src: local("RobotoSlab-Regular"),
   url(./assets/fonts/RobotoSlab-Regular.ttf) format("truetype");
}
</style>
