<template lang="html">
  <div class="gallery__wrapper">
    <div class="gallery">
      <div
        class="gallery__item"
        v-for="(image, imageIndex) in images"
        :key="image"
      >
        <img class="gallery__img" @click="index = imageIndex" v-lazy="image" />
      </div>
    </div>
    <div class="image__wrapper">

      <div class="image__card">
        <div class="control">
          <button @click="prev">
            <svg class="arrow">
              <use
                v-bind="{
                  'xlink:href': '/img/' + 'arrow' + '.svg#' + 'arrow'
                }"
              />
            </svg>
          </button>
          <button @click="next">
            <svg class="arrow --right">
              <use
                v-bind="{
                  'xlink:href': '/img/' + 'arrow' + '.svg#' + 'arrow'
                }"
              />
            </svg>
          </button>
        </div>
          <img class="main__img" :src="getImg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      images: [
        "/img/series/SEV_SMSHR_01.jpg",
        "/img/series/SEV_SMSHR_02.jpg",
        "/img/series/SEV_SMSHR_03.jpg",
        "/img/series/SEV_SMSHR_04.jpg",
        "/img/series/SEV_SMSHR_05.jpg",
        "/img/series/SEV_SMSHR_06.jpg",
        "/img/series/SEV_SMSHR_07.jpg",
        "/img/series/SEV_SMSHR_08.jpg",
        "/img/series/SEV_SMSHR_09.jpg",
        "/img/series/SEV_SMSHR_10.jpg",
        "/img/series/SEV_SMSHR_11.jpg",
        "/img/series/SEV_SMSHR_12.jpg",
        "/img/series/SEV_SMSHR_13.jpg",
        "/img/series/SEV_SMSHR_14.jpg",
        "/img/series/SEV_SMSHR_15.jpg",
        "/img/series/SEV_SMSHR_16.jpg",
        "/img/series/SEV_SMSHR_17.jpg",
        "/img/series/SEV_SMSHR_18.jpg",
        "/img/series/SEV_SMSHR_19.jpg",
        "/img/series/SEV_SMSHR_20.jpg",
        "/img/series/SEV_SMSHR_21.jpg",
        "/img/series/SEV_SMSHR_22.jpg",
        "/img/series/SEV_SMSHR_23.jpg",
        "/img/series/SEV_SMSHR_24.jpg",
        "/img/series/SEV_SMSHR_25.jpg",
        "/img/series/SEV_SMSHR_26.jpg",
        "/img/series/SEV_SMSHR_27.jpg",
        "/img/series/SEV_SMSHR_28.jpg",
        "/img/series/SEV_SMSHR_29.jpg",
        "/img/series/SEV_SMSHR_30.jpg",
        "/img/series/SEV_SMSHR_31.jpg",
        "/img/series/SEV_SMSHR_32.jpg",
        "/img/series/SEV_SMSHR_33.jpg",
        "/img/series/SEV_SMSHR_34.jpg",
        "/img/series/SEV_SMSHR_35.jpg",
        "/img/series/SEV_SMSHR_36.jpg",
        "/img/series/SEV_SMSHR_37.jpg",
        "/img/series/SEV_SMSHR_38.jpg",
        "/img/series/SEV_SMSHR_39.jpg",
        "/img/series/SEV_SMSHR_40.jpg",
        "/img/series/SEV_SMSHR_41.jpg",
        "/img/series/SEV_SMSHR_42.jpg",
        "/img/series/SEV_SMSHR_43.jpg",
        "/img/series/SEV_SMSHR_44.jpg",
        "/img/series/SEV_SMSHR_45.jpg",
        "/img/series/SEV_SMSHR_46.jpg",
        "/img/series/SEV_SMSHR_47.jpg",
        "/img/series/SEV_SMSHR_48.jpg",
        "/img/series/SEV_SMSHR_49.jpg",
        "/img/series/SEV_SMSHR_50.jpg",
        "/img/series/SEV_SMSHR_51.jpg",
        "/img/series/SEV_SMSHR_52.jpg",
        "/img/series/SEV_SMSHR_53.jpg",
        "/img/series/SEV_SMSHR_54.jpg",
        "/img/series/SEV_SMSHR_55.jpg",
        "/img/series/SEV_SMSHR_56.jpg",
        "/img/series/SEV_SMSHR_58.jpg",
        "/img/series/SEV_SMSHR_59.jpg",
        "/img/series/SEV_SMSHR_60.jpg",
        "/img/series/SEV_SMSHR_61.jpg"
      ],
      index: 0
    };
  },
  computed: {
    getImg() {
      return this.images.find((img, index) => index == this.index);
    }
  },
  methods: {
    next() {
      if (this.index < this.images.length-1){
      this.index++
      }
    },
    prev() {
      if (this.index > 0){
      this.index--
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.control {
  position: absolute;
  width: 110%;
  left: -5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.gallery {
  display: grid;
  grid-template-columns: repeat(3, 185px);
  grid-auto-rows: 180px;
  height: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  &__item {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__img {
    cursor: pointer;
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
  }
}
.image {
  &__card {
    margin: 70px 0 0;
    box-sizing: border-box;
    position: sticky;
    max-width: 100%;
    top: 100px;
  }
  &__wrapper {
    right: 0;
    grid-template-rows: 40%;
    margin-left: 70px;
  }
}
.main__img {
    display: flex;
    object-fit: contain;
    box-sizing: border-box;
    max-height: 350px;
    height: auto;
    width: 100%;
    flex-grow: 1;

}
.arrow {
  width: 24px;
  height: 24px;
  &.--right {
    transform: rotate(180deg);
  }
}
button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
.route {
  color: red;
}
@media screen and (max-width: 920px) {
  .gallery__wrapper {
    justify-content: center;
    max-width: 90%;
    margin: 0 auto;
  }
  .gallery {
    display: none;
    &__wrapper {
      grid-template-columns: 1fr;
    }
    &__img {
      max-height: 170px;
    }
  }
  .image__wrapper {
    margin-left: 0;
  }

  .control {
    width: 120%;
    left: -10%;
  }
}
@media screen and (max-width: 576px) {
  .main__img {
    max-height: 250px
  }
  .image__card {
    max-width: 90%;
    margin: 30% auto;
  }
  .control {
    width: 130%;
    left: -15%;
  }

}
@media screen and (max-width: 440px) {

}
</style>
