<template lang="html">
  <div class="contact">
    <a href="mailto:contact@vitalyseverov.com">contact@vitalyseverov.com</a>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="css" scoped >
a {
  font-size: 16px;
  text-decoration: none;
  color: #000000;
  opacity: .6;
}
.contact {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
