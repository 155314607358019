<template lang="html">
  <div id="nav" class="nav">
    <router-link class="page-label route" to="/">vitaly severov</router-link>
    <div class="menu">
      <div class="link">
        <router-link to="/series/summer_shore" class="menu__link route">series </router-link>
        <router-link to="/series/summer_shore" class="menu__sub-link route"
          >"summer shore"</router-link
        >
      </div>
      <div class="link">
        <router-link to="/books/summer_shore" class="menu__link route"
          >books
        </router-link>

        <router-link to="/books/summer_shore" class="menu__sub-link route">"summer shore" </router-link>
      </div>

      <router-link to="/about" class="menu__link route">about</router-link>
      <router-link to="/contact" class="menu__link route">contact</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 5%;
  margin-bottom: 70px;
  font-size: 15px;
}
.menu {
  width: 50%;
  display: flex;
  justify-content: space-around;
  &__link {
    &:hover > .menu__sub-link {
      display: block;
    }
  }
}
.link {
  position: relative;
  &:hover > .menu__sub-link {
    display: block;
  }
}
.menu__sub-link {
  color: red;
  left: 0;
  white-space: nowrap;
  top: 20px;
  position: absolute;
  display: none;
}
.page-label {
  font-size: 11px;
}
.route {
  text-decoration: none;
  color: #1e1e1e;

  font-size: 14px;
  opacity: .6;
  &:hover {
    text-decoration: underline;
  }
}
.page-label {
  white-space: nowrap;
}
@media screen and (max-width: 768px) {
  .menu {
    width: 100%;
    margin-top: 20px;
  }
  .nav {
    flex-direction: column;
  }
}
@media screen and (max-width: 568px) {
  .menu {
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
  }
  .menu__sub-link {
    top: 40px;
  }
  .nav {
    flex-direction: column;
    padding: 0;
  }
}
</style>
