<template lang="html">
  <div class="book__info">
    <div class="gallery__wrapper">
      <div class="image__wrapper">
        <div class="image__card">
          <div class="control">
            <button @click="prev">
              <svg class="arrow">
                <use
                  v-bind="{
                    'xlink:href': '/img/' + 'arrow' + '.svg#' + 'arrow'
                  }"
                />
              </svg>
            </button>
            <button @click="next">
              <svg class="arrow --right">
                <use
                  v-bind="{
                    'xlink:href': '/img/' + 'arrow' + '.svg#' + 'arrow'
                  }"
                />
              </svg>
            </button>
          </div>
          <img
            v-if="index < images.length - 1"
            class="main__img"
            :src="getImg"
            alt=""
          />
          <vueVimeoPlayer
            class="embed-container"
            :options="{
              responsive: true,
              portrait: false,
              title: false,
              byline: false
            }"
            v-else
            :videoId="videoId"
          />
        </div>
      </div>
      <div class="gallery">
        <div
          class="gallery__item"
          v-for="(image, imageIndex) in images"
          :key="image"
        >
          <img
            class="gallery__img"
            @click="index = imageIndex"
            v-lazy="image"
          />
        </div>
      </div>
    </div>
    <div class="description">
      <div class="description__text">
        <h3>
          Vitaly Severov <br />
          “Summer Shore”
        </h3>
        <p>
          Edition of 125 <br />
          10 х 19,4 cm <br />
          52 pages <br />
          Harmonica scroll <br />
          Fotografika publishing, <br />
          Saint petersburg, 2020 <br />
        </p>
        <p>
          Photographed 2016-2018, White Sea, <br />
          Summer Shore coastal area, Arkhangelsk region, Russia <br />
        </p>
        <p>
          Printed and maded by author, comes in clear <br />
          plastic audiocassette case <br />
        </p>
        <p>64°38’25.8''N 39°18’00.5''E <br /></p>
      </div>
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";

export default {
  components: {
    vueVimeoPlayer
  },
  data() {
    return {
      images: [
        "/img/books/book_1.JPG",
        "/img/books/book_2.JPG",
        "/img/books/book_3.JPG",
        "/img/books/book_4.JPG",
        "/img/books/book_5.JPG",
        "/img/books/book_6.JPG",
        "/img/books/book_7.JPG",
        "/img/books/book_8.JPG",
        "/a.jpg"
      ],
      index: 0,
      videoId: "471069805",
      playerReady: false
    };
  },
  computed: {
    getImg() {
      return this.images.find((img, index) => index == this.index);
    }
  },
  methods: {
    next() {
      if (this.index < this.images.length) {
        this.index++;
      }
    },
    prev() {
      if (this.index > 0) {
        this.index--;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.book__info {
  margin: 0 100px;
  display: grid;
  grid-template-columns: 1fr 0.7fr;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 30px;
  width: 100%;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  &__wrapper {
    margin-right: 100px;
  }
  &__item {
    margin: 0 auto;
    width: 100%;
  }
  &__img {
    display: block;
    max-width: 100%;
    margin-right: 100px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
}
.image {
  &__card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
  }
  &__wrapper {
    position: relative;
  }
}
.main__img {
  width: 100%;
}
.control {
  position: absolute;
  width: 115%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  top: 50%;
  transform: translate(0, -50%);
}
.arrow {
  width: 24px;
  height: 24px;
  &.--right {
    transform: rotate(180deg);
  }
}
.description {
  &__text {
    text-align: left;
    opacity: .7;
    p {
      line-height: 1.7;
      font-size: 11px;
    }
  }
}
.embed-container {
  width: 100% !important;
}
button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
h3 {
  font-weight: normal;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .description__text {
    text-align: center;
  }
  .book__info {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
  }
  .gallery__wrapper {
    margin-right: 0;
    max-width: 70%;
    margin: 50px auto;
  }
  .control {
    width: 125%;
  }
}
@media screen and (max-width: 576px) {
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
