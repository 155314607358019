import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Series from '../views/Series'
import Books from '../views/Books'
import Contact from '../views/Contact'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path:'/series/summer_shore',
    name: 'Series',
    component: Series
  },
  {
    path:'/books/summer_shore',
    name: 'Books',
    component: Books
  },
  {
    path:'/contact',
    name: 'Contact',
    component: Contact
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    // Redirect if fullPath begins with a hash (ignore hashes later in path)
    if (to.fullPath.substr(0,2) === "/#") {
        const path = to.fullPath.substr(2);
        next(path);
        return;
    }
    next();
});
export default router
