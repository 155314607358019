var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"book__info"},[_c('div',{staticClass:"gallery__wrapper"},[_c('div',{staticClass:"image__wrapper"},[_c('div',{staticClass:"image__card"},[_c('div',{staticClass:"control"},[_c('button',{on:{"click":_vm.prev}},[_c('svg',{staticClass:"arrow"},[_c('use',_vm._b({},'use',{
                  'xlink:href': '/img/' + 'arrow' + '.svg#' + 'arrow'
                },false))])]),_c('button',{on:{"click":_vm.next}},[_c('svg',{staticClass:"arrow --right"},[_c('use',_vm._b({},'use',{
                  'xlink:href': '/img/' + 'arrow' + '.svg#' + 'arrow'
                },false))])])]),(_vm.index < _vm.images.length - 1)?_c('img',{staticClass:"main__img",attrs:{"src":_vm.getImg,"alt":""}}):_c('vueVimeoPlayer',{staticClass:"embed-container",attrs:{"options":{
            responsive: true,
            portrait: false,
            title: false,
            byline: false
          },"videoId":_vm.videoId}})],1)]),_c('div',{staticClass:"gallery"},_vm._l((_vm.images),function(image,imageIndex){return _c('div',{key:image,staticClass:"gallery__item"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(image),expression:"image"}],staticClass:"gallery__img",on:{"click":function($event){_vm.index = imageIndex}}})])}),0)]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"description"},[_c('div',{staticClass:"description__text"},[_c('h3',[_vm._v(" Vitaly Severov "),_c('br'),_vm._v(" “Summer Shore” ")]),_c('p',[_vm._v(" Edition of 125 "),_c('br'),_vm._v(" 10 х 19,4 cm "),_c('br'),_vm._v(" 52 pages "),_c('br'),_vm._v(" Harmonica scroll "),_c('br'),_vm._v(" Fotografika publishing, "),_c('br'),_vm._v(" Saint petersburg, 2020 "),_c('br')]),_c('p',[_vm._v(" Photographed 2016-2018, White Sea, "),_c('br'),_vm._v(" Summer Shore coastal area, Arkhangelsk region, Russia "),_c('br')]),_c('p',[_vm._v(" Printed and maded by author, comes in clear "),_c('br'),_vm._v(" plastic audiocassette case "),_c('br')]),_c('p',[_vm._v("64°38’25.8''N 39°18’00.5''E "),_c('br')])])])}]

export { render, staticRenderFns }